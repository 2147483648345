import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { StaticTemplate } from '../../../templates/staticTemplate'
import { Layout } from '../../../components/layouts/layout'
import { UnstyledLink } from '../../../components/typography/UnstyledLink'
import { PageMeta } from '../../../components/page-meta'
import { CalculatorSectionModule } from '../../../components/modules/CalculatorSectionModule'
import { anchorLinkSections, CrossSellToutMortgage } from './constants'
import { usePages } from '../constants'
import { RentOrBuyCalculator } from '../../../components/calculators'

const RentVsOwnPage = ({ pageContext, location }) => {
  const [t] = useTranslation()
  const { calculatorsPage, mortgagePage } = usePages()

  const pageData = [
    {
      sectionTitle: t('RentVsOwnPage.subTitle', {
        defaultValue: 'Should I rent or buy a home?',
      }),
      richBody: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('RentVsOwnPage.textContent', {
                  defaultValue:
                    "If you're trying to decide whether to rent or buy, this calculator can help you see the big financial picture.",
                }),
                data: {},
              },
            ],
            data: {},
          },
          {
            nodeType: 'paragraph',
            content: [
              {
                nodeType: 'text',
                marks: [],
                value: t('RentVsOwnPage.textContent2a', {
                  defaultValue:
                    'While this calculator can help you compare the costs of buying versus renting, all numbers provided here are estimates. Please ',
                }),
                data: {},
              },
              {
                nodeType: 'hyperlink',
                content: [
                  {
                    nodeType: 'text',
                    marks: [],
                    value: t('RentVsOwnPage.textContent2b', {
                      defaultValue: 'talk to a Mortgage Loan Officer',
                    }),
                    data: {},
                  },
                ],
                data: {
                  uri: `/home-lending/mortgage-team`,
                },
              },
              {
                nodeType: 'text',
                marks: [],
                value: t('RentVsOwnPage.textContent2c', {
                  defaultValue: ' about what your actual costs may be.',
                }),
                data: {},
              },
            ],
            data: {},
          },
        ],
      },
    },
  ]

  const CalcTips = () => (
    <Trans i18nKey="RentVsOwnPage.calcTips">
      <p>
        Rent assumptions: <br />
        To help estimate <strong>annual rent increases</strong>, try doing an online search for
        "average rent increase" in your area.
      </p>
      <p>
        Purchase assumptions: <br />
        To help estimate <strong>annual appreciation rate</strong>, try doing an online search for
        "annual appreciation rate" in your area.
      </p>
      <p>
        <strong>Annual homeowners insurance premiums</strong> can vary significantly depending on
        the location and age of the house, and the type of coverage you choose. The Colorado
        Division of Insurance offers{' '}
        <UnstyledLink
          href="http://www.dora.state.co.us/pls/real/Ins_Survey_Reports.Report_Selection_Criteria?p_report_id=HOME&p_label="
          target="_blank"
          rel="noopener noreferrer"
          title="Visit the reporting tool"
        >
          a reporting tool
        </UnstyledLink>{' '}
        that lets you browse costs in your area.{' '}
      </p>
      <p>
        <strong>Annual property taxes</strong> are calculated based on assessed value in Colorado.
        To find the assessed value, take the value of the home you are interested in purchasing and
        multiply it by the current residential assessment rate. Then multiply this assessed value by
        the tax rate in your area. A quick online search for “annual property taxes” in your area
        can help you estimate this number.
      </p>
      <p>
        <strong>Annual maintenance costs</strong> will also vary based on the home you purchase.
        Consider factoring in $1,000 or more for this field.
      </p>
    </Trans>
  )

  return (
    <StaticTemplate pageContext={pageContext}>
      {({ navMenus }) => (
        <Layout
          location={location}
          pageContext={{ ...pageContext, pathname: mortgagePage.urlPath }}
          menu={{ ...navMenus, subnav: [calculatorsPage] }}
          showSubnav
        >
          <PageMeta
            language={pageContext.langKey}
            title={t('RentVsOwnPage.pageTitle', {
              defaultValue: 'Rent or Buy Calculator',
            })}
            pathname="/calculators/mortgage"
          />
          <CalculatorSectionModule
            pageTitle={t('RentVsOwnPage.title', {
              defaultValue: 'Mortgage Calculators',
            })}
            sections={pageData}
            calculator={<RentOrBuyCalculator />}
            anchorLinkSections={anchorLinkSections()}
            calcTips={<CalcTips />}
          />
          <CrossSellToutMortgage />
        </Layout>
      )}
    </StaticTemplate>
  )
}

RentVsOwnPage.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export { RentVsOwnPage }
export default RentVsOwnPage
